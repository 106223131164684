import React from 'react'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import SEO from '../components/seo'
import Nav from '../components/Nav'
import Superscript from '../components/Superscript'
import Footer from '../components/Footer'

import portrait from '../assets/karldanninger-portrait.jpg'

class About extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="About" />
        <Nav selected="about" />
        <Wrapper>
          <Superscript>Nice to meet you.</Superscript>
        </Wrapper>
        <ContentContainer>
          <Wrapper>
            <Content>
              <Portrait src={portrait} />
              <Small>
                LAST EDITED - JANUARY 25, 2019 <sup>*</sup>
              </Small>
              <p>
                Karl Danninger is a multi-disciplinary developer and designer
                for the modern web. He's one of those designers who does so in
                code.
              </p>
              <p>
                He has facilitated 15<sup>*</sup>{' '}
                <a
                  href="https://www.okgrow.com/posts/build-the-right-thing-first-with-design-sprints"
                  target="_blank"
                >
                  design sprints
                </a>
                , that have directed teams towards success.
              </p>
              <p>
                Karl is an amateur motorcycle mechanic and builder. When he's
                not heads down in a laptop, you can find Karl tinkering on a
                motorcycle engine on a custom build.
              </p>
              <div>
                <Small>CHECK OUT MORE</Small>
                <p>
                  <Links>
                    <Link to="/writing">Writing</Link> -{' '}
                    <a
                      href="https://www.instagram.com/karldanninger/"
                      target="_blank"
                    >
                      Instagram
                    </a>{' '}
                    -{' '}
                    <a href="https://twitter.com/karldanninger" target="_blank">
                      Twitter
                    </a>{' '}
                    -{' '}
                    <a
                      href="https://dribbble.com/karldanninger"
                      target="_blank"
                    >
                      Dribbble
                    </a>
                  </Links>
                </p>
              </div>
            </Content>
          </Wrapper>
        </ContentContainer>
        <Footer />
      </Container>
    )
  }
}

export default About

const Portrait = styled.img`
  float: left;
  width: 50%;
  margin-left: -33.3333%;
  margin-top: -120px;
  margin-right: 50px;
  margin-bottom: 25px;
  @media (max-width: 680px) {
    width: 82px;
    margin-top: -60px;
    margin-right: 30px;
    margin-bottom: 10px;
    margin-left: 0%;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;
  flex-direction: column;
  a {
    color: white;
    text-decoration: none;
    box-shadow: none;
    white-space: nowrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
  @media (max-width: 680px) {
    padding-top: 150px;
  }
`

const Small = styled.span`
  font-size: 10px;
  letter-spacing: 1px;
  color: rgb(141, 141, 141);
  font-family: Inter, sans-serif;
  sup {
    font-size: 12px !important;
    top: 0;
  }
`

const Content = styled.div`
  color: rgb(27, 27, 27);
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin-left: 25%;
  sup {
    color: red;
    font-size: 20px;
    font-family: Inter;
  }
  a {
    color: rgb(27, 27, 27);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }
  @media (max-width: 680px) {
    font-size: 18px;
    margin-left: 0;
  }
`

const ContentContainer = styled.div`
  background-color: white;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 100px 0;
  @media (max-width: 680px) {
    padding: 50px 0;
  }
`

const Wrapper = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 24px;
  > p {
    padding-bottom: 100px;
  }
  @media (max-width: 1000px) {
    width: auto;
    margin: 0 5vw;
  }
  @media (max-width: 680px) {
    > p {
      padding-bottom: 50px;
    }
  }
`

const Headline = styled.h1`
  color: white;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Inter, sans-serif;
  @media (max-width: 680px) {
    font-size: 20px;
  }
`

const Links = styled.span`
  font-family: Inter, sans-serif;
  color: rgb(27, 27, 27);
  margin: 40px 0;
`

const LinkTag = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: none;
  box-shadow: none;
  white-space: nowrap;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`
